



























































































































































































































































import { defineComponent } from '@vue/composition-api'
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import { TeachingTool } from '@/api/interfaces/teachingtool'
import TopBar from '@/components/organisms/o-top-bar.vue'
import store from '@/store'
import { QueryParams } from '@/store/api-plateform-utils'
import { usePagination, PaginationOptions } from '@/composables/pagination'
import Pagination from '@/components/base/Pagination.vue'
import { RawLocation } from 'vue-router'

export default defineComponent({
  setup (props, ctx) {
    const {
      paginationOptions,
      paginationRequestParams,
      nbPages,
      onSortBy,
      onSortDesc,
      }: PaginationOptions = usePagination(() => {
    return ctx.root.$store.state.teachingToolList.totalItems
  }) as PaginationOptions

    return {
      paginationOptions,
      paginationRequestParams,
      nbPages,
      onSortBy,
      onSortDesc,
    }
  },
  name: 'TeachingToolAssociateLogisticSessionList',
  components: {
    TopBar,
    Pagination,
  },
  data () {
    return {
      filters: {
        nameSearched: '',
        referenceSearched: '',
        areaSearched: '',
      },
      title: this.$t(
        'teaching_tool_associate_logistic_session_list.list.title_header',
      ),
      headers: [
        {
          value: 'checkbox',
          sortable: false,
        },
        {
          text: this.$t(
            'teaching_tool_associate_logistic_session_list.list.headers.name',
          ),
          align: 'start',
          value: 'name',
        },
        {
          text: this.$t(
            'teaching_tool_associate_logistic_session_list.list.headers.reference',
          ),
          value: 'reference',
        },
        {
          text: this.$t(
            'teaching_tool_associate_logistic_session_list.list.headers.area',
          ),
          value: 'area.name',
          sortable: false,
        },
      ],
      isSubmiting: false,
      checkedTeachingTools: [] as string[],
      loadedTeachingTools: new Map<string, TeachingTool>(),
    }
  },
  computed: {
    ...mapState('teachingToolList', {
      associateTeachingToolsList: 'list',
    }),
    ...mapState('area', {
      listAreas: 'list',
    }),
    ...mapState('logisticSessionForm', {
      logisticSession: 'logisticSession',
    }),
    ...mapGetters('navigation', {
      getLastRouteLogisticSession: 'getLastRouteLogisticSession',
    }),
    baseRequest (): QueryParams {
      const request: Record<string, unknown> = {
        name: this.filters.nameSearched,
        reference: this.filters.referenceSearched,
        area: this.filters.areaSearched,
        ...this.paginationRequestParams,
      }
      return request as QueryParams
    },
    idLogisticSession (): string | null {
      return this.$route.params.idLogisticSession || null
    },
  },
  created () {
    if (this.getLastRouteLogisticSession === null) {
      const route: RawLocation = {
        name: 'LogisticSession Edit',
        params: {
          idLogisticSession: this.idLogisticSession as string,
        },
      }
      this.$router.push(route)
      return
    }
    this.load(this.baseRequest).then(this.afterLoad)

    this.checkedTeachingTools = this.logisticSession.teachingTools.map(
      (t: TeachingTool) => t['@id'],
    )
    this.logisticSession.teachingTools.forEach((t: TeachingTool) => {
      this.loadedTeachingTools.set(t['@id'] as string, t)
    })
  },
  watch: {
    filters: {
      handler () {
        this.paginationOptions.page = 1
        this.load(this.baseRequest).then(this.afterLoad)
      },
      deep: true,
    },
    paginationOptions: {
      handler () {
        this.load(this.baseRequest).then(this.afterLoad)
      },
      deep: true,
    },
  },
  methods: {
    afterLoad (loadedList: TeachingTool[]) {
      loadedList.forEach((t: TeachingTool) => {
        this.loadedTeachingTools.set(t['@id'] as string, t)
      })
    },
    ...mapActions('teachingToolList', {
      load: 'load',
    }),
    ...mapActions('user', {
      sendSuccessMessage: 'sendSuccessMessage',
    }),
    ...mapMutations('logisticSessionForm', {
      addTeachingTool: 'addTeachingTool',
    }),
    associateCheckedTeachingToolsToLogisticSession () {
      this.isSubmiting = true

      this.checkedTeachingTools.forEach((teachingToolIri: string): void => {
        if (this.loadedTeachingTools.has(teachingToolIri)) {
          this.addTeachingTool(
            this.loadedTeachingTools.get(teachingToolIri) as TeachingTool,
          )
        }
      })
      this.$router.push(this.getLastRouteLogisticSession)
    },
  },
  beforeRouteEnter (to, from, next) {
    store.commit('navigation/addRoute', to)
    next()
  },
  beforeRouteLeave (to, from, next) {
    if (to.name !== 'TeachingTool Create') {
      store.commit('navigation/removeRoute')
    }
    next()
  },
})
